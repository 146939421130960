import { User } from "../models/user";

export class AbstractCompany {
  companyId?: string;
  unitName?: string;
  unitName2?: string;
  legalName?: string;
  isLegalEntity?: boolean;
  legalForm?: string;
  targetGroup?: string;
  erpid?: string;
  vatId?: string;
  creditRating?: string;
  blocked?: boolean;
  clientId?: string;
  corporateId?: string;
  payingCorporateId?: string;
  active?: boolean;
  clientCompany?: boolean;
  phone?: string;
  fax?: string;
  companyEmail?: string;
  webpage?: string;
  visitorAdress1?: string;
  visitorAdress2?: string;
  visitorAreaCode?: string;
  visitorCity?: string;
  visitorCountry?: string;
  country?: string;
  adress1?: string;
  adress2?: string;
  zipCode?: string;
  city?: string;
  county?: string;
  region?: string;
  municipality?: string;
  customerContactOffice?: string;
  companyType?: string;
  clientCompanyType: string;
  primaryContactId: string;
  keyAccount?: string;
  users?: User[];
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  constructor(input: AbstractCompany) {
    this.companyId = input.companyId;
    this.unitName = input.unitName;
    this.unitName2 = input.unitName2;
    this.legalName = input.legalName;
    this.isLegalEntity = input.isLegalEntity;
    this.legalForm = input.legalForm;
    this.targetGroup = input.targetGroup;
    this.erpid = input.erpid;
    this.vatId = input.vatId;
    this.creditRating = input.creditRating;
    this.blocked = input.blocked;
    this.clientId = input.clientId;
    this.corporateId = input.corporateId;
    this.payingCorporateId = input.payingCorporateId;
    this.active = input.active;
    this.clientCompany = input.clientCompany;
    this.phone = input.phone;
    this.fax = input.fax;
    this.companyEmail = input.companyEmail;
    this.webpage = input.webpage;
    this.visitorAdress1 = input.visitorAdress1;
    this.visitorAdress2 = input.visitorAdress2;
    this.visitorAreaCode = input.visitorAreaCode;
    this.visitorCity = input.visitorCity;
    this.visitorCountry = input.visitorCountry;
    this.country = input.country;
    this.adress1 = input.adress1;
    this.adress2 = input.adress2;
    this.zipCode = input.zipCode;
    this.city = input.city;
    this.county = input.county;
    this.region = input.region;
    this.municipality = input.municipality;
    this.customerContactOffice = input.customerContactOffice;
    this.keyAccount = input.keyAccount;
    this.clientCompanyType = input.clientCompanyType;
    this.primaryContactId = input.primaryContactId;
    this.users = input.users;
    this.createdBy = input.createdBy;
    this.createdAt = input.createdAt;
    this.updatedAt = input.updatedAt;
  }
}

export enum TypeOfBusiness {
  ab = 'companies.type_of_business.ab',
  kommun = 'companies.type_of_business.kommun'
}

export enum CompanyType {
  ramboll = 'ramboll',
  client = 'client',
  partner = 'partner',
  vendor = 'vendor',
}

export enum CompanyCountry {
  norway = 'no',
  sweden = 'se',
}

export interface Country {
  name: string;
  code: string;
}

export interface Countries {
  denmark: Country;
  finland: Country;
  norway: Country;
  sweden: Country;
}

export const countries: Countries = {
  denmark: {
    name: 'companies.countries.denmark.name',
    code: 'companies.countries.denmark.code'
  },
  finland: {
    name: 'companies.countries.finland.name',
    code: 'companies.countries.finland.code',
  },
  norway: {
    name: 'companies.countries.norway.name',
    code: 'companies.countries.norway.code'
  },
  sweden: {
    name: 'companies.countries.sweden.name',
    code: 'companies.countries.sweden.code'
  }
}